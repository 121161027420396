import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { Image, Flex, Stack, Text, useBreakpointValue, Button, Icon, Accordion, AccordionButton, AccordionItem, AccordionPanel, HStack, Menu, MenuButton, MenuDivider, MenuItem, MenuList, useColorModeValue, VStack, Box, MenuGroup, Portal } from '@chakra-ui/react';
import {
	BsHouse,
	BsEnvelope,
	BsAward,
	BsFileText,
	BsConeStriped,
	BsGraphUp,
	BsGear,
	BsFileBarGraph,
	BsWrench,
	BsArrowRepeat,
	BsExclamationCircle,
	BsShieldFillExclamation,
	BsPersonCircle,
	BsQuestionCircle,
	BsPeople,
	BsPlusCircle,
	BsBarChart,
	BsGraphUpArrow,
	BsMortarboard,
	BsRocket,
	BsGlobe2,
	BsPlayBtn
} from 'react-icons/bs';
import { VscMortarBoard } from 'react-icons/vsc';
import { FaClipboardCheck, FaEnvelope, FaHardHat, FaGraduationCap } from 'react-icons/fa';

import { RootState } from '../../redux/rootReducer';
import { getClickthroughURL, selectClassicObbi } from '../../features/auth/auth.actions';

import { CheckProductTierFlag } from '../../helpers/Utilities';

import { NavButton } from './NavButton';

import Logo from '../../assets/logos/obbi_logo_1051x640.png';

export const Sidebar: React.FC<any> = ({ toggleMenu, messageCount, isAdmin }) => {
	const org = useSelector((state: RootState) => state.authReducer);
	const user = useSelector((state: RootState) => state.authReducer.user);
	const location = useLocation();
	const navigateTo = useNavigate();
	const isMobile = useBreakpointValue({ base: true, lg: false });

	const getInitialExpandedIndex = () => {
		const path = location.pathname;
		if (path.includes('/messages') || path.includes('/notifications')) return 0;
		if (path.includes('/certificates')) return 1;
		if (path.includes('/tasks') || path.includes('/actions')) return 2;
		if (path.includes('/incidents') || path.includes('/riskassessments')) return 3;
		if (path.includes('/training') || path.includes('/documents')) return 4;
		return undefined;
	};

	const [expandedIndex, setExpandedIndex] = React.useState<number | undefined>(getInitialExpandedIndex());

	// Update expanded index when route changes
	useEffect(() => {
		const newIndex = getInitialExpandedIndex();
		setExpandedIndex(newIndex);
	}, [location.pathname]);

	const handleClickThrough = async (path: string) => {
		let url: string = await getClickthroughURL(path);
		window.open(url, '_self');
	};

	const isPathActive = (path: string) => window.location.pathname.includes(path);

	const renderNavButton = (
		label: string,
		icon: any,
		path: string,
		onClick: () => void,
		toggleAlert?: boolean,
		alertCount?: number
	) => (
		<NavButton
			w='100%'
			label={label}
			icon={icon}
			bgColor={isPathActive(path) ? 'green.500' : ''}
			color={isPathActive(path) ? 'white' : ''}
			onClick={onClick}
			toggleAlert={toggleAlert}
			alertCount={alertCount}
		/>
	);

	const renderAccordionItem = (
		title: string,
		icon: any,
		iconColor: string,
		items: {
			label: string,
			icon: any,
			path: string,
			onClick?: () => void,
			toggleAlert?: boolean,
			alertCount?: number
		}[]
	) => {
		return (
			<AccordionItem pb={1.5} cursor={'default'}>
				<AccordionButton
					pt={2}
					px={3}
					_hover={{ bg: '' }}
				>
					<Flex flex='1' textAlign='left'>
						<Text
							color='gray.700'
							fontSize='xs'
							fontWeight={700}
						>
							{title}
						</Text>
					</Flex>
				</AccordionButton>
				<AccordionPanel p={0}>
					<Stack>
						{items.map(item => item && renderNavButton(
							item.label,
							item.icon,
							item.path,
							item.onClick || (() => {
								navigateTo(item.path);
								if (isMobile) toggleMenu();
							}),
							item.toggleAlert,
							item.alertCount
						))}
					</Stack>
				</AccordionPanel>
			</AccordionItem>
		);
	};

	const createNewMenu = (
		<Box overflow={'visible'} w='100%' position="static">
			<Menu direction='ltr' placement='right-end' strategy="fixed">
				<MenuButton
					w='100%'
					transition="all 0.3s"
					_focus={{ boxShadow: "none" }}
					className="menu-button-wrapper"
				>
					<Button
						w='100%'
						size='sm'
						colorScheme='green'
						leftIcon={<Icon as={BsPlusCircle} />}
						className="inner-button"
					>
						Create New
					</Button>
				</MenuButton>

				<Portal>
					<MenuList
						fontSize={'xs'}
						fontWeight={500}
						color={'gray.700'}
						bg={useColorModeValue("white", "gray.900")}
						borderColor={useColorModeValue("gray.200", "gray.700")}
						zIndex={2000}
					>
						<MenuGroup title="Create..." fontSize={'xs'} fontWeight={600}>
							<MenuItem
								onClick={() => { (user.isTeamManager || user.isAdmin) && navigateTo('/actions/create', { state: { create: true } }) }}
								isDisabled={!(user.isTeamManager || user.isAdmin)}
								opacity={(user.isTeamManager || user.isAdmin) ? 1 : 0.4}
								_disabled={{ cursor: 'not-allowed' }}
								_hover={{ bg: 'gray.50' }}
							>
								<HStack>
									<Icon as={BsWrench} fontSize={'sm'} color={(user.isTeamManager || user.isAdmin) ? "green.500" : "gray.300"} />
									<a>New Corrective Action</a>
								</HStack>
							</MenuItem>

							<MenuItem
								onClick={() => {
									(user.isTeamManager || user.isAdmin) &&
										(org?.orgriskassessmentsenabled && CheckProductTierFlag(user?.producttier?.ptriskassessmentsflag)) && navigateTo('/riskassessments/create');
								}}
								isDisabled={
									!(user.isTeamManager || user.isAdmin) ||
									!(org?.orgriskassessmentsenabled && CheckProductTierFlag(user?.producttier?.ptriskassessmentsflag))
								}
								opacity={
									(user.isTeamManager || user.isAdmin) &&
										(org?.orgriskassessmentsenabled && CheckProductTierFlag(user?.producttier?.ptriskassessmentsflag)) ?
										1 : 0.4}
								_disabled={{ cursor: 'not-allowed' }}
								_hover={{ bg: 'gray.50' }}
							>
								<HStack>
									<Icon as={BsExclamationCircle} fontSize={'sm'} color={(user.isTeamManager || user.isAdmin) ? "green.500" : "gray.300"} />
									<a>New Risk Assessment</a>
								</HStack>
							</MenuItem>

							<MenuItem
								onClick={() => { (user.isTeamManager || user.isAdmin) && handleClickThrough('CreateDocument') }}
								isDisabled={!(user.isTeamManager || user.isAdmin)}
								opacity={(user.isTeamManager || user.isAdmin) ? 1 : 0.4}
								_disabled={{ cursor: 'not-allowed' }}
								_hover={{ bg: 'gray.50' }}
							>
								<HStack>
									<Icon as={BsFileText} fontSize={'sm'} color={(user.isTeamManager || user.isAdmin) ? "green.500" : "gray.300"} />
									<a>New Document</a>
								</HStack>
							</MenuItem>

							<MenuItem
								onClick={() => { (user.isTeamManager || user.isAdmin) && handleClickThrough('CreateCertificate') }}
								isDisabled={!(user.isTeamManager || user.isAdmin)}
								opacity={(user.isTeamManager || user.isAdmin) ? 1 : 0.4}
								_disabled={{ cursor: 'not-allowed' }}
								_hover={{ bg: 'gray.50' }}
							>
								<HStack>
									<Icon as={BsAward} fontSize={'sm'} color={(user.isTeamManager || user.isAdmin) ? "green.500" : "gray.300"} />
									<a>New Certificate</a>
								</HStack>
							</MenuItem>

							<MenuDivider />

							<MenuItem
								onClick={() => { (user.isTeamManager || user.isAdmin) && handleClickThrough('CreateTask') }}
								isDisabled={!(user.isTeamManager || user.isAdmin)}
								opacity={(user.isTeamManager || user.isAdmin) ? 1 : 0.4}
								_disabled={{ cursor: 'not-allowed' }}
								_hover={{ bg: 'gray.50' }}
							>
								<HStack>
									<Icon as={FaClipboardCheck} fontSize={'sm'} color={(user.isTeamManager || user.isAdmin) ? "green.500" : "gray.300"} />
									<a>New Task</a>
								</HStack>
							</MenuItem>

							<MenuItem
								onClick={() => { (user.isTeamManager || user.isAdmin) && handleClickThrough('CreateTrainingCourse') }}
								isDisabled={!(user.isTeamManager || user.isAdmin)}
								opacity={(user.isTeamManager || user.isAdmin) ? 1 : 0.4}
								_disabled={{ cursor: 'not-allowed' }}
								_hover={{ bg: 'gray.50' }}
							>
								<HStack>
									<Icon as={VscMortarBoard} fontSize={'sm'} color={(user.isTeamManager || user.isAdmin) ? "green.500" : "gray.300"} />
									<a>New Training Course</a>
								</HStack>
							</MenuItem>

							<MenuItem
								onClick={() => { (user.isTeamManager || user.isAdmin) && handleClickThrough('CreateTeam') }}
								isDisabled={!(user.isTeamManager || user.isAdmin)}
								opacity={(user.isTeamManager || user.isAdmin) ? 1 : 0.4}
								_disabled={{ cursor: 'not-allowed' }}
								_hover={{ bg: 'gray.50' }}
							>
								<HStack>
									<Icon as={BsPeople} fontSize={'sm'} color={(user.isTeamManager || user.isAdmin) ? "green.500" : "gray.300"} />
									<a>New Team</a>
								</HStack>
							</MenuItem>

							<MenuItem
								onClick={() => { (user.isTeamManager || user.isAdmin) && handleClickThrough('CreateUser') }}
								isDisabled={!(user.isTeamManager || user.isAdmin)}
								opacity={(user.isTeamManager || user.isAdmin) ? 1 : 0.4}
								_disabled={{ cursor: 'not-allowed' }}
								_hover={{ bg: 'gray.50' }}
							>
								<HStack>
									<Icon as={BsPersonCircle} fontSize={'sm'} color={(user.isTeamManager || user.isAdmin) ? "green.500" : "gray.300"} />
									<a>New User</a>
								</HStack>
							</MenuItem>
						</MenuGroup>
					</MenuList>
				</Portal>
			</Menu>
		</Box>
	);

	const manageMenu = (
		<Box overflow={'visible'} w='100%' position="static">
			<Menu direction='ltr' placement='right-end' strategy="fixed">
				<MenuButton
					w='100%'
					transition="all 0.3s"
					_focus={{ boxShadow: "none" }}
					className="menu-button-wrapper"
				>
					<Button
						w='100%'
						size='sm'
						colorScheme='green'
						leftIcon={<Icon as={BsGear} />}
						_hover={{ bgColor: '#248451' }}
						className="inner-button"
					>
						Manage
					</Button>
				</MenuButton>

				<Portal>
					<MenuList
						fontSize={'xs'}
						fontWeight={500}
						color={'gray.700'}
						bg={"white"}
						borderColor={"gray.200"}
						zIndex={2000}
					>
						<MenuGroup title="Manage" fontSize={'xs'} fontWeight={600}>
							<MenuItem
								onClick={() => { user.permissions.Tasks.read && handleClickThrough('ManageTasks') }}
								isDisabled={!user.permissions.Tasks.read}
								opacity={user.permissions.Tasks.read ? 1 : 0.4}
								_disabled={{ cursor: 'not-allowed' }}
								_hover={{ bg: 'gray.50' }}
							>
								<HStack>
									<Icon as={FaClipboardCheck} fontSize={'sm'} color={user.permissions.Tasks.read ? "green.500" : "gray.300"} />
									<a>Manage Tasks</a>
								</HStack>
							</MenuItem>

							<MenuItem
								onClick={() => { user.permissions.Courses.read && handleClickThrough('ManageTrainingCourses') }}
								isDisabled={!user.permissions.Courses.read}
								opacity={user.permissions.Courses.read ? 1 : 0.4}
								_disabled={{ cursor: 'not-allowed' }}
								_hover={{ bg: 'gray.50' }}
							>
								<HStack>
									<Icon as={BsMortarboard} fontSize={'sm'} color={user.permissions.Courses.read ? "green.500" : "gray.300"} />
									<a>Manage Training Courses</a>
								</HStack>
							</MenuItem>

							<MenuItem
								onClick={() => { user.permissions.Incidents.read && handleClickThrough('ManageIncidentForms') }}
								isDisabled={!user.permissions.Incidents.read}
								opacity={user.permissions.Incidents.read ? 1 : 0.4}
								_disabled={{ cursor: 'not-allowed' }}
								_hover={{ bg: 'gray.50' }}
							>
								<HStack>
									<Icon as={BsConeStriped} fontSize={'sm'} color={user.permissions.Incidents.read ? "green.500" : "gray.300"} />
									<a>Configure Incident Forms</a>
								</HStack>
							</MenuItem>

							<MenuDivider />

							<MenuItem
								onClick={() => { user.permissions.Teams.read && handleClickThrough('ManageTeams') }}
								isDisabled={!user.permissions.Teams.read}
								opacity={user.permissions.Teams.read ? 1 : 0.4}
								_disabled={{ cursor: 'not-allowed' }}
								_hover={{ bg: 'gray.50' }}
							>
								<HStack>
									<Icon as={BsPeople} fontSize={'sm'} color={user.permissions.Teams.read ? "green.500" : "gray.300"} />
									<a>Manage Your Teams</a>
								</HStack>
							</MenuItem>

							<MenuItem
								onClick={() => { user.permissions.Users.read && handleClickThrough('ManageUsers') }}
								isDisabled={!user.permissions.Users.read}
								opacity={user.permissions.Users.read ? 1 : 0.4}
								_disabled={{ cursor: 'not-allowed' }}
								_hover={{ bg: 'gray.50' }}
							>
								<HStack>
									<Icon as={BsPersonCircle} fontSize={'sm'} color={user.permissions.Users.read ? "green.500" : "gray.300"} />
									<a>Manage Your Users</a>
								</HStack>
							</MenuItem>

							<MenuDivider />

							<MenuItem
								onClick={() => { user.permissions.Tasks.read && handleClickThrough('AdminTaskReports') }}
								isDisabled={!user.permissions.Tasks.read}
								opacity={user.permissions.Tasks.read ? 1 : 0.4}
								_disabled={{ cursor: 'not-allowed' }}
								_hover={{ bg: 'gray.50' }}
							>
								<HStack>
									<Icon as={FaClipboardCheck} fontSize={'sm'} color={user.permissions.Tasks.read ? "green.500" : "gray.300"} />
									<a>View Task Reports</a>
								</HStack>
							</MenuItem>

							<MenuItem
								onClick={() => { user.permissions.Courses.read && handleClickThrough('AdminTrainingReports') }}
								isDisabled={!user.permissions.Courses.read}
								opacity={user.permissions.Courses.read ? 1 : 0.4}
								_disabled={{ cursor: 'not-allowed' }}
								_hover={{ bg: 'gray.50' }}
							>
								<HStack>
									<Icon as={BsMortarboard} fontSize={'sm'} color={user.permissions.Courses.read ? "green.500" : "gray.300"} />
									<a>View Training Reports</a>
								</HStack>
							</MenuItem>

							<MenuItem
								onClick={() => { user.permissions.AuditLog.read && handleClickThrough('AuditLog') }}
								isDisabled={!user.permissions.AuditLog.read}
								opacity={user.permissions.AuditLog.read ? 1 : 0.4}
								_disabled={{ cursor: 'not-allowed' }}
								_hover={{ bg: 'gray.50' }}
							>
								<HStack>
									<Icon as={BsFileText} fontSize={'sm'} color={user.permissions.AuditLog.read ? "green.500" : "gray.300"} />
									<a>View Audit Log</a>
								</HStack>
							</MenuItem>

							<MenuDivider />

							<MenuItem onClick={() => { window.open('https://help.obbisolutions.com/en_US/release-notes', '_blank') }} _hover={{ bg: 'gray.50' }}>
								<HStack>
									<Icon as={BsRocket} fontSize={'sm'} color="green.500" />
									<a>What's New in Obbi</a>
								</HStack>
							</MenuItem>
						</MenuGroup>
					</MenuList>
				</Portal>
			</Menu>
		</Box >
	);

	const helpMenu = (
		<Box overflow={'visible'} w='100%' position="static">
			<Menu direction='ltr' placement='right-end' strategy="fixed">
				<MenuButton
					w='100%'
					transition="all 0.3s"
					_focus={{ boxShadow: "none" }}
				>
					<Button
						w='100%'
						size='sm'
						variant='outline'
						leftIcon={<Icon as={BsQuestionCircle} />}
						className="inner-button"
					>
						Help
					</Button>
				</MenuButton>

				<Portal>
					<MenuList
						fontSize={'xs'}
						fontWeight={500}
						color={'gray.700'}
						bg={'white'}
						borderColor={'gray.200'}
						zIndex={2000}
					>
						<MenuGroup>
							<MenuItem onClick={() => { window.open('https://help.obbisolutions.com/', '_blank') }} _hover={{ bg: 'gray.50' }}>
								<HStack>
									<Icon as={BsQuestionCircle} fontSize={'sm'} color={'green.500'} />
									<a>Obbi Help Guide</a>
								</HStack>
							</MenuItem>
							<MenuItem onClick={() => { }} _hover={{ bg: 'gray.50' }}>
								<HStack>
									<Icon as={BsPlayBtn} fontSize={'sm'} color={'green.500'} />
									<a>Getting Started Guide</a>
								</HStack>
							</MenuItem>
							<MenuDivider />
							<MenuItem onClick={() => { window.open('https://help.obbisolutions.com/en_US/', '_blank') }} _hover={{ bg: 'gray.50' }}>
								<HStack>
									<Icon as={BsRocket} fontSize={'sm'} color={'green.500'} />
									<a>What's New in Obbi</a>
								</HStack>
							</MenuItem>
							<MenuItem onClick={() => { window.open('mailto:support@obbisolutions.com', '_blank') }} _hover={{ bg: 'gray.50' }}>
								<HStack>
									<Icon as={BsGlobe2} fontSize={'sm'} color={'green.500'} />
									<a>Contact Us</a>
								</HStack>
							</MenuItem>
						</MenuGroup>
					</MenuList>
				</Portal>
			</Menu>
		</Box>
	);

	const newSidebar = (
		<Flex
			as='section'
			position='fixed'
			minHeight='full'
			height='100%'
			width='235px'
			overflowY='auto'
			overflowX='visible'
			zIndex={100}
			style={{ scrollbarWidth: 'thin', scrollbarColor: 'transparent' }}
		>
			<Flex
				flex='1'
				bg='bg-surface'
				maxW={{ base: 'full', sm: 'xs' }}
				py={{ base: 6, sm: 4 }}
				px={{ base: 4, sm: 6 }}
				overflowY='auto'
			>
				<Stack shouldWrapChildren width='100%'>
					{
						!isMobile && (
							<Image
								src={Logo}
								alt='Obbi logo'
								className='hover-pop'
								h='40px'
								cursor='pointer'
								onClick={() => {
									navigateTo('/home');
									toggleMenu();
								}}
							/>
						)
					}
					{
						isMobile && org.orgnewobbi === 1 && (
							<Button
								mb={3}
								size='sm'
								variant='outline'
								onClick={() => {
									selectClassicObbi().then(() => {
										handleClickThrough('Home');
									});
								}}
							>
								<Icon as={BsArrowRepeat} mr={2} /> Switch to Classic Obbi
							</Button>
						)
					}
					<Stack pt={{ base: 0, lg: 2 }}>
						{renderNavButton('Home', BsHouse, 'home', () => {
							navigateTo('home');
							if (isMobile) toggleMenu();
						})}

						{user.isTeamManager && (
							<>
								{renderNavButton('Team Overview', BsGraphUpArrow, 'teamreports', () => {
									handleClickThrough('TeamUpdateReport');
									if (isMobile) toggleMenu();
								})}

								{renderNavButton('Reports', BsBarChart, 'teamreports', () => {
									handleClickThrough('TeamReports');
									if (isMobile) toggleMenu();
								})}
							</>
						)}

						{renderNavButton('Messages', BsEnvelope, 'messages', () => {
							navigateTo('messages');
							if (isMobile) toggleMenu();
						})}
					</Stack>
					<Accordion
						defaultIndex={[0, 1, 2, 3, 4]}
						onChange={(index: any) => setExpandedIndex(index === expandedIndex ? undefined : index)}
					>
						{/* {renderAccordionItem('Inbox', FaEnvelope, 'gray.500', [
							{ label: 'Messages', icon: BsEnvelope, path: '/messages', toggleAlert: messageCount > 0, alertCount: messageCount },
						])} */}
						{renderAccordionItem('Compliance', FaClipboardCheck, 'green.500', [
							{ label: 'Certificates', icon: BsAward, path: '/certificates' },
						])}
						{renderAccordionItem('Operations', FaHardHat, 'yellow.500', [
							{ label: 'Tasks', icon: FaClipboardCheck, path: '/tasks' },
							{ label: 'Actions', icon: BsWrench, path: '/actions' },
						])}
						{renderAccordionItem('Safety', BsShieldFillExclamation, 'red.500', [
							{ label: 'Incidents', icon: BsConeStriped, path: '/incidents' },
							org?.orgriskassessmentsenabled && CheckProductTierFlag(user?.producttier?.ptriskassessmentsflag) && {
								label: 'Risk Assessments',
								icon: BsExclamationCircle,
								path: '/riskassessments'
							},
						].filter(Boolean))}
						{renderAccordionItem('Training', FaGraduationCap, 'purple.500', [
							{ label: 'Training Courses', icon: VscMortarBoard, path: '/training' },
							{ label: 'Documents', icon: BsFileText, path: '/documents' },
						])}
					</Accordion>

					<VStack
						position={'fixed'}
						left={3}
						bottom={0}
						paddingTop={'10px'}
						paddingBottom={'20px'}
						w='210px'
						bg='white'
						zIndex={101}
					>
						{createNewMenu}
						{manageMenu}
						{helpMenu}
					</VStack>
				</Stack>
			</Flex>
		</Flex>
	);

	const classicSidebar = (
		<Flex
			as='section'
			position='fixed'
			minHeight='full'
			height='100%'
			width='235px'
			overflowY='auto'
			zIndex={100}
			style={{ scrollbarWidth: 'thin', scrollbarColor: 'transparent transparent' }}
		>
			<Flex
				flex='1'
				bg='bg-surface'
				maxW={{ base: 'full', sm: 'xs' }}
				py={{ base: 6, sm: 5 }}
				px={{ base: 4, sm: 6 }}
				overflowY='auto'
			>
				<Stack shouldWrapChildren width='100%'>
					{!isMobile && (
						<Image
							src={Logo}
							alt='Obbi logo'
							className='hover-pop'
							w='100px'
							cursor='pointer'
							onClick={() => {
								navigateTo('/home');
								toggleMenu();
							}}
						/>
					)}
					{isMobile && org.orgnewobbi === 1 && (
						<Button
							mb={3}
							size='sm'
							variant='outline'
							onClick={() => {
								selectClassicObbi().then(() => {
									handleClickThrough('Home');
								});
							}}
						>
							<Icon as={BsArrowRepeat} mr={2} /> Switch to Classic Obbi
						</Button>
					)}
					<Stack pt={{ base: 0, lg: 4 }}>
						<NavButton
							label='Home'
							icon={BsHouse}
							bgColor={window.location.pathname.includes('home') ? 'green.500' : ''}
							color={window.location.pathname.includes('home') ? 'white' : ''}
							onClick={() => {
								navigateTo('/home');
								if (isMobile) toggleMenu();
							}}
						/>
						{user.isTeamManager && (
							<>
								<NavButton
									label='Team Overview'
									icon={BsGraphUp}
									bgColor={window.location.pathname.includes('teamreports') ? 'green.500' : ''}
									color={window.location.pathname.includes('teamreports') ? 'white' : ''}
									onClick={() => {
										handleClickThrough('TeamUpdateReport');
										if (isMobile) toggleMenu();
									}}
								/>
								<NavButton
									label='Reports'
									icon={BsFileBarGraph}
									bgColor={window.location.pathname.includes('admin') ? 'green.500' : ''}
									color={window.location.pathname.includes('admin') ? 'white' : ''}
									onClick={() => {
										handleClickThrough('TeamReports');
										if (isMobile) toggleMenu();
									}}
								/>
							</>
						)}
					</Stack>
					<Text pt={1} color='gray.500' fontSize='xs' fontWeight={600}>
						Compliance
					</Text>
					<Stack>
						<NavButton
							label='Tasks'
							icon={FaClipboardCheck}
							bgColor={window.location.pathname.includes('tasks') ? 'green.500' : ''}
							color={window.location.pathname.includes('tasks') ? 'white' : ''}
							onClick={() => {
								navigateTo('/tasks');
								if (isMobile) toggleMenu();
							}}
						/>
						<NavButton
							label='Actions'
							icon={BsWrench}
							bgColor={window.location.pathname.includes('actions') ? 'green.500' : ''}
							color={window.location.pathname.includes('actions') ? 'white' : ''}
							onClick={() => {
								navigateTo('/actions');
								if (isMobile) toggleMenu();
							}}
						/>
						<NavButton
							label='Certificates'
							icon={BsAward}
							bgColor={window.location.pathname.includes('certificates') ? 'green.500' : ''}
							color={window.location.pathname.includes('certificates') ? 'white' : ''}
							onClick={() => {
								navigateTo('/certificates');
								if (isMobile) toggleMenu();
							}}
						/>
					</Stack>
					<Text pt={1} color='gray.500' fontSize='xs' fontWeight={600}>
						Awareness
					</Text>
					<Stack>
						<NavButton
							label='Training'
							icon={VscMortarBoard}
							bgColor={window.location.pathname.includes('training') ? 'green.500' : ''}
							color={window.location.pathname.includes('training') ? 'white' : ''}
							onClick={() => {
								navigateTo('/training');
								if (isMobile) toggleMenu();
							}}
						/>
						<NavButton
							label='Documents'
							icon={BsFileText}
							bgColor={window.location.pathname.includes('documents') ? 'green.500' : ''}
							color={window.location.pathname.includes('documents') ? 'white' : ''}
							onClick={() => {
								navigateTo('/documents');
								if (isMobile) toggleMenu();
							}}
						/>
						<NavButton
							label='Messages'
							icon={FaEnvelope}
							bgColor={window.location.pathname.includes('messages') ? 'green.500' : ''}
							color={window.location.pathname.includes('messages') ? 'white' : ''}
							onClick={() => {
								navigateTo('/messages');
								if (isMobile) toggleMenu();
							}}
							toggleAlert={messageCount > 0}
							alertCount={messageCount}
						/>
					</Stack>
					<Text pt={1} color='gray.500' fontSize='xs' fontWeight={600}>
						Safety & Standards
					</Text>
					<Stack>
						{org?.orgriskassessmentsenabled && CheckProductTierFlag(user?.producttier?.ptriskassessmentsflag) && (
							<NavButton
								label='Risk Assessments'
								icon={BsExclamationCircle}
								bgColor={window.location.pathname.includes('riskassessments') ? 'green.500' : ''}
								color={window.location.pathname.includes('riskassessments') ? 'white' : ''}
								onClick={() => {
									navigateTo('/riskassessments');
									if (isMobile) toggleMenu();
								}}
							/>
						)}
						<NavButton
							label='Incidents'
							icon={BsConeStriped}
							bgColor={window.location.pathname.includes('incidents') ? 'green.500' : ''}
							color={window.location.pathname.includes('incidents') ? 'white' : ''}
							onClick={() => {
								navigateTo('/incidents');
								if (isMobile) toggleMenu();
							}}
						/>
					</Stack>
					{isAdmin && (
						<>
							<Text pt={1} color='gray.500' fontSize='xs' fontWeight={600}>
								Management
							</Text>
							<Stack mt={2}>
								<NavButton
									label='Admin'
									icon={BsGear}
									bgColor={window.location.pathname.includes('admin') ? 'green.500' : ''}
									color={window.location.pathname.includes('admin') ? 'white' : ''}
									onClick={() => {
										handleClickThrough('Admin');
										if (isMobile) toggleMenu();
									}}
								/>
							</Stack>
						</>
					)}
				</Stack>
			</Flex>
		</Flex>
	);

	return org?.orgnewsidebarenabled ? newSidebar : classicSidebar;
};